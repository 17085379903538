import Zone from '@/model/zone';
import CRUDRepository from './crudRepository';
import RestService from '../restService';

export default class ZoneRepository extends CRUDRepository<Zone> {
  private static readonly BASE_URL = '/zones';

  private static readonly ADD_URL = '/zones/add';

  private static readonly EDIT_URL = '/zones/edit'

  private static readonly DELETE_URL = '/zones/delete'

  constructor(service: RestService) {
    super(
      service,
      ZoneRepository.BASE_URL,
      ZoneRepository.ADD_URL,
      ZoneRepository.EDIT_URL,
      ZoneRepository.DELETE_URL,
    );
  }
}
