import AxiosRestService from '@/network/axiosRestService';

// At the beginning the following buttons will be selected
const FILTERS = ['active', 'future']

const initialState = () => ({
  index: [],
  loading: false,
  errorLoadingIndex: false,
  filter: FILTERS,
  isPersonal: false,
})

export default {
  namespaced: true,
  // -----------------------------------------------------------------
  state: initialState(),
  // -----------------------------------------------------------------
  getters: {
    index: (state) => state.index,
    loading: (state) => state.loading,
    errorLoadingIndex: (state) => state.errorLoadingIndex,
    errorDownloadingInvoice: (state) => state.errorDownloadingInvoice,
    filter: (state) => state.filter,
    isPersonal: (state) => state.isPersonal,
  },
  // -----------------------------------------------------------------
  mutations: {
    setIndex: (state, roles) => { state.index = roles },
    setLoading: (state, loading) => { state.loading = loading },
    setErrorLoadingIndex: (state, errorLoadingIndex) => {
      state.errorLoadingIndex = errorLoadingIndex
    },
    setErrorDownloadingInvoice: (state, errorDownloadingInvoice) => {
      state.errorDownloadingInvoice = errorDownloadingInvoice
    },
    setFilter: (state, filter) => { state.filter = filter },
    setIsPersonal: (state, isPersonal) => { state.isPersonal = isPersonal },
    reset(state) {
      const newState = initialState()
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key]
      })
    },
  },
  // -----------------------------------------------------------------
  actions: {
    loadIndex: (context) => {
      const personal = context.state.isPersonal ? '&type=personal' : ''
      context.commit('setLoading', true)
      return AxiosRestService
        .get(`/reservations?filter=${context.state.filter}${personal}`)
        .then((response) => {
          context.commit('setIndex', response.data)
          context.commit('setErrorLoadingIndex', false)
        })
        .catch(() => context.commit('setErrorLoadingIndex', true))
        .finally(() => context.commit('setLoading', false))
    },
    filter: (context, filter) => {
      context.commit('setFilter', filter)
      context.dispatch('loadIndex')
    },
    setIsPersonal: (context, isPersonal) => {
      context.commit('setIsPersonal', isPersonal)
      context.dispatch('loadIndex')
    },
    downloadInvoice: (context, transaction) => AxiosRestService
      .get(`/transactions/getReportFor/${transaction.id}`)
      .then((response) => {
        context.commit('setErrorDownloadingInvoice', false)
        const link = document.createElement('a')
        link.href = `${process.env.VUE_APP_API_BASE_URL}/transactions/getReportFor/${transaction.id}`
        document.body.appendChild(link)
        link.click()
      })
      .catch(() => {
        context.commit('setErrorDownloadingInvoice', true)
      })
      .finally(() => {
        context.commit('setLoading', false)
      }),
  },
}
