import Model from '@/model/model';
import RestService from '../restService';

/* eslint-disable no-useless-constructor */
export default abstract class CRUDRepository<T extends Model> {
  constructor(
    protected readonly service: RestService,
    private readonly baseUrl: string,
    private readonly addUrl: string,
    private readonly editUrl: string,
    private readonly deleteUrl: string,
  ) {}

  async getAll() {
    const response = await this.service.get<T[]>(this.baseUrl);
    return response.data;
  }

  async add(item: T) {
    await this.service.post(this.addUrl, item);
  }

  async edit(item: T) {
    await this.service.patch(`${this.editUrl}/${item.id}`, item);
  }

  async delete(item: T) {
    await this.service.delete(`${this.deleteUrl}/${item.id}`);
  }
}
