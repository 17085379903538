/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
import { GetterTree, MutationTree, ActionTree } from 'vuex';
import Dialog, { DialogType } from '@/dialogs/dialog';
import dialogs from '@/dialogs';
import BaseModule from '../module';
import { State, RootState } from '../types';

interface DialogsState extends State {
  dialogs: Dialog[];
}

class DialogsModule extends BaseModule<DialogsState> {
  protected initialState(): DialogsState {
    return {
      dialogs: [],
    }
  }

  protected buildGettersTree(): GetterTree<DialogsState, RootState> {
    return {
      currentDialog: (state) => {
        if (state.dialogs.length > 0) {
          return [dialogs.currentDialog()];
        }
        return [];
      },
      dialogs: (state) => state.dialogs,
    }
  }

  protected buildMutationsTree(): MutationTree<DialogsState> {
    return {
      addDialog(state, dialog) {
        state.dialogs.push(dialog);
        dialogs.fire(dialog);
      },
      removeDialog(state, id) {
        const index = state.dialogs.findIndex(
          (dialog) => dialog.id === id,
        )
        state.dialogs.splice(index, 1);
        dialogs.removeDialog(id);
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      next(state, id) {
        state.dialogs.shift();
        dialogs.removeCurrent();
      },
    }
  }

  protected buildActionsTree(): ActionTree<DialogsState, RootState> {
    return {
      info(context, dialog: Dialog) {
        dialog.type = DialogType.INFO;
        context.commit('addDialog', dialog);
      },
      success(context, dialog: Dialog) {
        dialog.type = DialogType.SUCCESS;
        context.commit('addDialog', dialog);
      },
      error(context, dialog: Dialog) {
        dialog.type = DialogType.ERROR;
        context.commit('addDialog', dialog);
      },
      warning(context, dialog: Dialog) {
        dialog.type = DialogType.WARNING;
        context.commit('addDialog', dialog);
      },
      question(context, dialog: Dialog) {
        dialog.type = DialogType.QUESTION;
        context.commit('addDialog', dialog);
      },
    }
  }
}

export default new DialogsModule(true);
