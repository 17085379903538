/* eslint-disable class-methods-use-this */
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { differenceInDays, parseISO } from 'date-fns';
import BaseModule from '../module';
import { RootState, State } from '../types';

/**
 * Documentation of the public API that is used can be found here:
 * https://restcountries.com/#api-endpoints-v3
 */

const COUNTRIES = 'countries';
const DAYS_UNTIL_DIRTY = 90;

async function fetchCountriesData() {
  let result;

  const fields = 'name,flags,continents,cca2';
  await fetch(`https://restcountries.com/v3.1/all?fields=${fields}`, { method: 'GET' })
    .then((response) => {
      if (!response.ok) {
        return Promise.reject();
      }

      return response.text()
    })
    .then((text) => {
      result = JSON.parse(text);
    })
    .catch((_) => {
      result = undefined;
    })

  return result;
}

/**
 * Takes the given data, expected to be a Continents object, and sorts the individual lists.
 * @param data The continents data object
 * @returns The sorted continents data object
 */
function sortContinents(data: any): any {
  function compareCountriesByName(a: any, b: any) : number {
    return (a.name.common as string).localeCompare(b.name.common as string);
  }

  data.Africa.sort(compareCountriesByName);
  data.Antarctica.sort(compareCountriesByName);
  data.Asia.sort(compareCountriesByName);
  data.Europe.sort(compareCountriesByName);
  data['North America'].sort(compareCountriesByName);
  data.Oceania.sort(compareCountriesByName);
  data['South America'].sort(compareCountriesByName);

  return data;
}

/**
 * Iterates over the raw countries data, and sorts them into the corresponding continent
 * @param data The raw countries data, as returned by the API call
 * @returns An object containing the continents as keys, and the country object arrays as values
 */
function parseDataByContinents(data: object[]) : any {
  const result = {
    Africa: new Array(0),
    Antarctica: new Array(0),
    Asia: new Array(0),
    Europe: new Array(0),
    'North America': new Array(0),
    Oceania: new Array(0),
    'South America': new Array(0),
  };

  // Define the objectKey type to access the result keys dynamically
  type ObjectKey = keyof typeof result;

  data.forEach((element) => {
    const key = (element as any).continents[0] as ObjectKey;
    result[key].push(element);
  });

  return sortContinents(result);
}

/**
 * Checks, whether the data in the local storage should be updated. This depends on
 * whether there is actual data for the countries in the local storage, and if the data
 * is not older than DAYS_UNTIL_DIRTY days.
 * @param data The countries data from the local storage
 * @returns True, if the data should get updated. False otherwise
 */
function isDirty(data: any): boolean {
  return data === null
    || differenceInDays(parseISO(JSON.parse(data as string)?.fetched), new Date())
      > DAYS_UNTIL_DIRTY;
}

interface CountriesState extends State {
  rawData: object[];
  lastFetch: Date | undefined;
  byContinents: object[];
  loading: boolean;
}

class CountriesModule extends BaseModule<CountriesState> {
  protected initialState(): CountriesState {
    const countries = window.localStorage.getItem(COUNTRIES);

    const emptyState = {
      rawData: [],
      byContinents: [],
      lastFetch: undefined,
      loading: true,
    };

    if (!countries) {
      return emptyState;
    }

    // We know that countries can't be null here, so cast it to clarify this for the interpreter
    const countriesObject = JSON.parse(countries as string);

    if (differenceInDays(parseISO(countriesObject?.fetched), new Date()) > DAYS_UNTIL_DIRTY) {
      return emptyState;
    }

    return {
      rawData: countriesObject.data,
      byContinents: parseDataByContinents(countriesObject.data),
      lastFetch: countriesObject.fetched,
      loading: false,
    };
  }

  protected buildGettersTree(): GetterTree<CountriesState, RootState> {
    return {
      rawData: (state) => state.rawData,
      byContinents: (state) => state.byContinents,
      lastFetch: (state) => state.lastFetch,
      loading: (state) => state.loading,
      nameFromCountryCode: (state) => (code: any) => {
        const element: any = state.rawData.find((value: any) => value.cca2 === code);
        if (element) {
          return element.name.common;
        }

        return code;
      },
    };
  }

  protected buildMutationsTree(): MutationTree<CountriesState> {
    return {
      updateRawData(state, data) {
        state.rawData = data;
      },
      updateByContinents(state, data) {
        state.byContinents = data;
      },
      updateLastFetch(state, data) {
        state.lastFetch = data;
      },
      updateLoading(state, data) {
        state.loading = data;
      },
    };
  }

  protected buildActionsTree(): ActionTree<CountriesState, RootState> {
    return {
      /**
       * Checks, whether the country data is dirty, and fetches new data if this is the case.
       * Otherwise, loads the country data from the localStorage.
       * @param context The context, is typically provided automatically
       * @param param1 An object containing the following attributes:
       * - forceUpdate (boolean), determines whether the data should be forcefully loaded
       * - onSuccess (callback), a function that should be called once the data was loaded
       * @returns void
       */
      fetchData(context, { forceUpdate = false, onSuccess = () => false }) {
        context.commit('updateLoading', true);
        const storedData = window.localStorage.getItem(COUNTRIES);

        if (!forceUpdate && !isDirty(storedData)) {
          const dataObject = JSON.parse(storedData as string);
          context.commit('updateRawData', dataObject.data);
          context.commit('updateByContinents', parseDataByContinents(dataObject.data));
          context.commit('updateLastFetch', dataObject.fetched);
          // Call the provided callback
          onSuccess();
          context.commit('updateLoading', false);
          return;
        }

        fetchCountriesData().then((result) => {
          if (result) {
            const fetched = new Date();
            context.commit('updateRawData', result);
            context.commit('updateByContinents', parseDataByContinents(result));
            context.commit('updateLastFetch', fetched);

            window.localStorage.setItem(
              COUNTRIES,
              JSON.stringify({
                fetched: fetched.toISOString(),
                data: result,
              }),
            );

            // Call the provided callback
            onSuccess();
            context.commit('updateLoading', false);
          }
        });
      },
    };
  }
}

export default new CountriesModule(true);
