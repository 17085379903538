import camelCase from 'lodash.camelcase';
import { ModuleTree } from 'vuex';
import { RootState } from '../types';

const requiredModules = require.context('.', false, /\.ts|js$/);
const modules: ModuleTree<RootState> = {};

requiredModules.keys().forEach((filename) => {
  if (filename === './index.ts') {
    return;
  }

  const moduleName = camelCase(filename.replace(/(\.js|\.ts)/g, ''));
  modules[moduleName] = requiredModules(filename).default || requiredModules(filename);
});

export default modules;
