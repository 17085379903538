/* eslint-disable no-useless-constructor */
/* eslint-disable max-classes-per-file */
export class DialogType {
  private constructor(
    public readonly variable: string,
    public readonly name: string,
    public readonly icon: string,
    public readonly color: string,
  ) {}

  public static readonly SUCCESS = new DialogType('SUCCESS', 'success', 'mdi-checkbox-marked-circle-outline', 'success');

  public static readonly INFO = new DialogType('INFO', 'info', 'mdi-information-outline', 'info');

  public static readonly WARNING = new DialogType('WARNING', 'warning', 'mdi-alert-circle-outline', 'warning');

  public static readonly ERROR = new DialogType('ERROR', 'error', 'mdi-alert-circle-outline', 'error');

  public static readonly QUESTION = new DialogType('QUESTION', 'question', 'mdi-help-circle-outline', 'primary');
}

let counter = 1;

export default class Dialog {
  public type?: DialogType;

  public title?: string;

  public subtitle?: string;

  public validInput?: string;

  public showCancelButton?: boolean;

  public okAction?: () => void;

  public cancelAction?: () => void;

  public id?: number;

  constructor() {
    this.id = counter;
    counter += 1;
  }
}
