<template>
  <v-app>
    <v-fade-transition mode='out-in'>
      <component
        :is="getComponentName"
        class="fill-height"
      >
        <v-slide-x-reverse-transition mode='out-in'>
          <router-view></router-view>
        </v-slide-x-reverse-transition>
      </component>
    </v-fade-transition>
    <v-snackbar bottom :value="updateExists" :timeout="-1" color="white">
      <span style="color: black;">{{ $t('New content available!') }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          class="ml-10"
          plain
          elevation="0"
          color="black"
          :ripple="false"
          v-bind="attrs"
          :loading="updateLoading"
          @click="refreshApp"
        >{{ $t('Reload') }}</v-btn>
        <v-btn icon class="ml-3" @click="updateExists = false" :ripple="false">
          <audi-icon icon="cancel"/>
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>
<script>
import Vue from 'vue';
import AudiIcon from '@/components/audi/AudiIcon.vue';
import NavbarLayout from './layouts/NavbarLayout.vue';
import FullScreenLayout from './layouts/FullScreen.vue';

export default Vue.extend({
  components: {
    NavbarLayout,
    FullScreenLayout,
    AudiIcon,
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used as default
    title: 'Book-n-Park',
  },
  data() {
    return {
      registration: null,
      updateExists: false,
      updateLoading: false,
    }
  },
  computed: {
    getComponentName() {
      return this.$route?.meta?.layout;
    },
  },
  created() {
    document.addEventListener('swUpdated', (event) => {
      this.registration = event.detail
      if (this.registration && this.registration.waiting) {
        // Checks if it is iOS
        const isIOS = [
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod',
        ].includes(navigator.platform)
          // iPad on iOS 13 detection
          || (navigator.userAgent.includes('Mac') && 'ontouchend' in document)

        if (!isIOS) {
          this.updateExists = true
        }
      }
    }, { once: true })
    // navigator.serviceWorker.addEventListener('controllerchange', () => {
    //   // We'll also need to add 'refreshing' to our data originally set to false.
    //   if (this.refreshing) return
    //   this.refreshing = true
    //   // Here the actual reload of the page occurs
    //   window.location.reload()
    // })
  },
  methods: {
    refreshApp() {
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) {
        this.updateExists = false
        return
      }
      this.updateLoading = true;

      // Send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
      setTimeout(() => {
        this.updateExists = false;
        this.updateLoading = false;
        window.location.reload();
      }, 3000);
    },
  },
});
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

@font-face {
  font-family: 'Audi-Normal';
  src: url('fonts/AudiType-Normal_4.03.ttf');
}

@font-face {
  font-family: 'Audi-Bold';
  src: url('fonts/AudiType-Bold_4.03.ttf');
}

@font-face {
  font-family: 'Audi-Extended';
  src: url('fonts/AudiType-ExtendedNormal_4.03.ttf');
}

@font-face {
  font-family: 'Audi-Extended-Bold';
  src: url('fonts/AudiType-ExtendedBold_4.03.ttf');
}

@font-face {
  font-family: 'Audi-Wide-Light';
  src: url('fonts/AudiType-WideLight_4.03.ttf');
}
</style>

<style>
/* Override the global tooltip content style, to adjust the styling to audi */
.v-tooltip__content {
  box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.1) !important;
  color: black !important;
  background-color: white !important;
}

/* Override the global style for inset switches */
.v-input--switch--inset .v-input--switch__track {
  opacity: unset !important;
}
.theme--light.v-input--switch .v-input--switch__track {
  color: #D9D9D9;
}
/* Ensure that for all colors, the thumb will always be white */
.v-input--switch__thumb.theme--light[class$="--text"] {
  color: white !important;
}
.v-input--switch--inset .v-input--switch__thumb {
  transition-duration: 0.15s !important;
  transition-delay: 0s !important;
  color: white !important;
}
.v-input__slot:hover .v-input--switch__thumb {
  scale: 0.8 !important;
}
.v-input--switch--inset.v-input--is-dirty
.v-input__slot:hover
.v-input--switch__thumb {
  transform: translate(24px, 0) !important;
}
/* Override the styles for buttons and disabled buttons with background according to Audi design */
.v-btn {
  letter-spacing: unset !important;
}
.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: #B2B2B2 !important;
}
.theme--light.v-btn.v-btn--disabled {
  color: #F2F2F2 !important;
}
</style>
