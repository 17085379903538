import Vue from 'vue'
import AxiosRestService from '@/network/axiosRestService';

const initialState = () => ({
  index: [],
  errorLoadingIndex: false,
  loading: false,
})

export default {
  namespaced: true,
  // -----------------------------------------------------------------
  state: initialState(),
  // -----------------------------------------------------------------
  getters: {
    index: (state) => state.index,
    errorLoadingIndex: (state) => state.errorLoadingIndex,
    loading: (state) => state.loading,
  },
  // -----------------------------------------------------------------
  mutations: {
    setIndex: (state, index) => Vue.set(state, 'index', index),
    setErrorLoadingIndex: (state, errorLoadingIndex) => Vue.set(state, 'errorLoadingIndex', errorLoadingIndex),
    setLoadingState: (state, loading) => Vue.set(state, 'loading', loading),
    reset(state) {
      const newState = initialState()
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key]
      })
    },
  },
  // -----------------------------------------------------------------
  actions: {
    loadIndex: (context) => AxiosRestService
      .get('/reservations')
      .then((response) => {
        context.commit('setIndex', response.data)
        context.commit('setErrorLoadingIndex', false)
      })
      .catch(() => context.commit('setErrorLoadingIndex', true)),
    fetchReservations: (context) => AxiosRestService
      .get('/reservations/personal_index')
      .then((response) => {
        context.commit('setIndex', response.data)
        context.commit('setErrorLoadingIndex', false)
      })
      .catch(() => context.commit('setErrorLoadingIndex', true)),
    fetchReservationByParameter: (context, searchString) => {
      context.commit('setLoadingState', true)
      return AxiosRestService
        .get(`/reservations/getReservationsByNumber_ajax/${searchString}`)
        .then((response) => {
          context.commit('setLoadingState', false)
          context.commit('setIndex', response.data)
        })
    },
  },
}
