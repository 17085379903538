import ReservationDates from './reservationDates';

class Reservation {
  public id: number;

  public dates: ReservationDates;

  public state: string;

  public price: number;

  public contracts: Array<string>;

  constructor(
    id: number,
    dates: ReservationDates,
    state: string,
    price: number,
    contracts: Array<string>,
  ) {
    this.id = id;
    this.dates = dates;
    this.state = state;
    this.price = price;
    this.contracts = contracts;
  }
}

export default Reservation;
