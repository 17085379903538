/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
import Dialog from './dialog';

class Dialogs {
  public static dialogs: Dialog[] = [];

  public currentDialog() {
    if (Dialogs.dialogs.length > 0) {
      return Dialogs.dialogs[0];
    }
    return undefined;
  }

  public removeDialog(id: number) {
    const index = Dialogs.dialogs.findIndex(
      (dialog) => dialog.id === id,
    )
    Dialogs.dialogs.splice(index, 1);
  }

  public removeCurrent() {
    Dialogs.dialogs.shift();
  }

  public fire(dialog: Dialog) {
    Dialogs.dialogs.push(dialog);
  }
}

export default new Dialogs();
