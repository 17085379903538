import Model from './model';

// eslint-disable-next-line no-shadow
export enum Roles {
  ADMIN='Admin',
  RESERVATION_MANAGER='Reservation Manager',
  VISITOR='Visitor',
  SUPPORT='Support'
}

export default interface Role extends Model {
  name: Roles;
}
