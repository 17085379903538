import Vue from 'vue';
import VueI18n, { LocaleMessages } from 'vue-i18n';
import { Languages } from './model/languages';

Vue.use(VueI18n);

const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);

function loadSupportedLanguages() {
  const supportedLocales: Array<{locale: string, key:string}> = [];
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      supportedLocales.push({ locale: matched[1], key });
    }
  });
  return supportedLocales;
}

function loadLocaleMessages() {
  const supportedLocales = loadSupportedLanguages()
  const messages: LocaleMessages = {}
  supportedLocales.forEach((locale) => { messages[locale.locale] = locales(locale.key) })
  return messages;
}

function getBrowserLocale() {
  const navigatorLocale = navigator.languages !== undefined
    ? navigator.languages[0]
    : navigator.language

  if (!navigatorLocale) {
    return undefined
  }

  const trimmedLocale = navigatorLocale.trim().split(/-|_/)[0]

  return trimmedLocale
}

function supportedLocalesInclude(locale: any) {
  return Object.values(Languages).includes(locale)
}

export function getStartingLocale() {
  const browserLocale = getBrowserLocale()
  if (browserLocale && supportedLocalesInclude(browserLocale)) {
    return browserLocale
  }
  return process.env.VUE_APP_I18N_LOCALE || 'en'
}

const dateTimeFormats: any = {
  en: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
    timeOnly: {
      hour: 'numeric',
      minute: 'numeric',
    },
    dateOnly: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
    fullDateWithWeekday: {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
  de: {
    short: {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
    timeOnly: {
      hour: 'numeric',
      minute: 'numeric',
    },
    dateOnly: {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    },
    fullDateWithWeekday: {
      weekday: 'long',
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
  it: {
    short: {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
    timeOnly: {
      hour: 'numeric',
      minute: 'numeric',
    },
    dateOnly: {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    },
    fullDateWithWeekday: {
      weekday: 'long',
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
  fr: {
    short: {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
    timeOnly: {
      hour: 'numeric',
      minute: 'numeric',
    },
    dateOnly: {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    },
    fullDateWithWeekday: {
      weekday: 'long',
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
};

export default new VueI18n({
  locale: getStartingLocale(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  formatFallbackMessages: true,
  messages: loadLocaleMessages(),
  silentFallbackWarn: true,
  silentTranslationWarn: true,
  dateTimeFormats,
});
