<template>
  <div>
    <alert-pool></alert-pool>
    <dialog-pool></dialog-pool>
    <terms-of-service-dialog></terms-of-service-dialog>
    <offline-notification></offline-notification>
    <v-navigation-drawer
      app
      color="navbar"
      dark
      style="z-index: 100;"
      floating
      width="300px"
    >
      <div class="ma-3">
        <nav-bar-content></nav-bar-content>
        <div class="powered-by-background desktop"/>
        <div
          class="d-inline-flex powered-by-positioning justify-center"
          @click="openBnP"
          style="z-index: 10000002; cursor: pointer;"
        >
          <span class="powered-by-text text-body-1" style="font-size: 11px !important">
            powered by
          </span>
          <v-img
            src="@/assets/logo_monochrome_light_gray.svg"
            contain
            max-width="80px"
            class="ml-2"
            style="margin-top: -2px"
          />
        </div>
      </div>
    </v-navigation-drawer>

    <v-main>
      <v-container
        fluid
        :class="{
          'px-12': $vuetify.breakpoint.smAndUp && !$route.name === 'AddReservation',
        }"
        :style="
          $route.name === 'AddReservation' ? 'max-width: 100%; padding: 0px;' : 'max-width: 1200px'
        "
      >
        <slot></slot>
      </v-container>
    </v-main>

    <v-navigation-drawer
      v-model="navbarMobile"
      app
      dark
      id="roundedBottomSheet"
      @click.stop=""
      scrollable
      style="background-color: #333 !important; z-index: 1000001"
      width="300px"
    >
      <div class="ma-3" style="z-index: 10000000">
        <nav-bar-content @close-navbar="navbarMobile = false"/>
        <div class="powered-by-background"/>
        <div
          class="d-inline-flex powered-by-positioning justify-center"
          style="z-index: 10000002; cursor: pointer;"
          @click="openBnP"
        >
          <span class="powered-by-text text-body-1" style="font-size: 11px !important;">
            powered by
          </span>
          <v-img
            src="@/assets/logo_monochrome_light_gray.svg"
            contain
            max-width="80px"
            class="ml-2"
            style="margin-top: -2px"
          />
        </div>
      </div>
    </v-navigation-drawer>

    <v-bottom-sheet
      v-model='searchSheet'
      id="roundedBottomSearch"
      class="d-flex flex-column"
    >
      <v-sheet
        color="navbar"
        dark
        class="fill-height px-3 pt-6 rounded-t-lg pb-14"
      >
        <v-text-field
          id="search-field"
          :label="$t('Type to Search')"
          rounded
          outlined
          dense
          filled
          prepend-inner-icon="mdi-magnify"
          :value="$store.getters.searchString"
          @input="$store.dispatch('updateSearchString', $event)"
          clearable
        ></v-text-field>
      </v-sheet>
    </v-bottom-sheet>

    <v-app-bar
      app
      color="white"
      dark
      fixed
      elevation="0"
      v-if='$vuetify.breakpoint.mobile'
      height="56px"
      :class="{'rounded-t-lg': navbarMobile || searchSheet}"
      style="transition: border-radius 0.5s; z-index: 1000000"
    >
      <v-btn
        icon
        :ripple="false"
        @click="toggleNavSheet"
      >
        <audi-icon icon="off-canvas"/>
      </v-btn>
      <v-fade-transition>
        <span
          v-if="appBarTitleVisible"
          style="color: #444; font-family: Audi-Wide-Light; font-size: 16px"
        >
          {{ pageTitle }}
        </span>
      </v-fade-transition>
      <v-spacer></v-spacer>
      <v-scale-transition>
        <v-btn
          icon
          @click="toggleSearchSheet"
          v-if="$route.meta.searchable"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </v-scale-transition>
    </v-app-bar>

    <v-fab-transition origin="center center 0">
      <v-btn
        v-if="showFab"
        @click.stop="$emit('addClick')"
        :to="$route.meta.addButtonLink"
        fab
        fixed
        bottom
        right
        style="color: white"
        class="mb-3 mx-auto z-index-202"
        color="black"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
  </div>
</template>
<script>
import NavBarContent from '@/components/NavBarContent.vue';
import AlertPool from '@/components/AlertPool.vue';
import DialogPool from '@/components/DialogPool.vue';
import OfflineNotification from '@/components/OfflineNotificationComponent.vue'
import TermsOfServiceDialog from '@/components/dialogs/TermsOfServiceDialog.vue';
import AudiIcon from '@/components/audi/AudiIcon.vue';
import urls from '../router/urls';

export default {
  components: {
    NavBarContent,
    AlertPool,
    DialogPool,
    OfflineNotification,
    TermsOfServiceDialog,
    AudiIcon,
  },
  data() {
    return {
      urls,
      navbarMobile: false,
      searchSheet: false,
      appBarTitleVisible: false,
    };
  },
  created() {
    // Determine if the appBarTitle is visible initially
    this.setAppBarTitleVisible();

    window.addEventListener('scroll', this.setAppBarTitleVisible);
  },
  methods: {
    setAppBarTitleVisible() {
      if (window.scrollY > 100) {
        this.appBarTitleVisible = true;
        return;
      }

      this.appBarTitleVisible = false;
    },
    searchClick() {
      this.$store.dispatch('searching', !this.$store.getters.searching);
    },
    toggleNavSheet() {
      this.searchSheet = false;
      this.navbarMobile = !this.navbarMobile;
    },
    toggleSearchSheet() {
      this.navbarMobile = false;
      this.searchSheet = !this.searchSheet;
    },
    openBnP() {
      window.open('https://www.book-n-park.de', '_blank');
    },
  },
  computed: {
    pageTitle() {
      return this.$t(this.$route?.meta?.navbar?.title ?? '');
    },
    showFab() {
      return (
        this.$vuetify.breakpoint.mobile
        && this.$route.meta.addButtonLink
        && !this.navbarMobile
        && !this.searchSheet
      );
    },
  },
};
</script>
<style scoped>
.z-index-202 {
  z-index: 202;
}
.powered-by-text {
  font-size: 11px;
  color: #acacac;
}
.powered-by-positioning {
  position: absolute;
  bottom: 16px;
  left: 10%;
  width: 80%;
}
.powered-by-background {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 68px;
  background-color: #333;
  border-top: 1px solid #4C4C4C;
  z-index: 10000001;
}
.powered-by-background.desktop {
  width: 94%;
  border-top: none;
}
</style>
<style>
.v-bottom-sheet.v-dialog {
  border-radius: 8px !important;
}
.v-footer {
  z-index: 203;
}

a:hover {
  text-decoration: none !important;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}
</style>
