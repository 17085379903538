import {
  Module, ActionTree, MutationTree, GetterTree,
} from 'vuex';
import { RootState, State } from './types';

export default abstract class BaseModule<S extends State> implements Module<S, RootState> {
  public state: S;

  public getters: GetterTree<S, RootState>;

  public mutations: MutationTree<S>;

  public actions: ActionTree<S, RootState>;

  constructor(public namespaced: boolean) {
    this.state = this.initialState();

    this.getters = this.buildGettersTree();

    this.mutations = {
      ...this.buildMutationsTree(),
      reset: (state: State) => {
        const newState = this.initialState();
        Object.keys(newState).forEach((key) => {
          state[key] = newState[key];
        });
      },
    }

    this.actions = {
      ...this.buildActionsTree(),
      reset({ commit }) {
        commit('reset');
      },
    }
  }

  protected abstract initialState(): S;

  protected abstract buildGettersTree(): GetterTree<S, RootState>;

  protected abstract buildMutationsTree(): MutationTree<S>;

  protected abstract buildActionsTree(): ActionTree<S, RootState>;
}
