import Vue from 'vue'
import AxiosRestService from '@/network/axiosRestService';

const initialState = () => ({
  index: [],
  currentAnnouncements: [],
  loading: false,
  editing: false,
  adding: false,
  deleting: false,
  errorLoadingIndex: false,
  errorDeletingItem: false,
  errorEditingItem: false,
  errorAddingItem: false,
  successDeletingItem: false,
  successEditingItem: false,
  successAddingItem: false,
})

export default {
  namespaced: true,
  // -----------------------------------------------------------------
  state: initialState(),
  // -----------------------------------------------------------------
  getters: {
    index: (state) => state.index,
    loading: (state) => state.loading,
    editing: (state) => state.editing,
    deleting: (state) => state.deleting,
    adding: (state) => state.adding,
    errorLoadingIndex: (state) => state.errorLoadingIndex,
    errorEditingItem: (state) => state.errorEditingItem,
    errorDeletingItem: (state) => state.errorDeletingItem,
    errorAddingItem: (state) => state.errorAddingItem,
    successEditingItem: (state) => state.successEditingItem,
    successDeletingItem: (state) => state.successDeletingItem,
    successAddingItem: (state) => state.successAddingItem,

    currentAnnouncements: (state) => state.currentAnnouncements,
  },
  // -----------------------------------------------------------------
  mutations: {
    setIndex: (state, announcements) => Vue.set(state, 'index', announcements),
    setCurrentAnnouncements: (state, announcements) => Vue.set(state, 'currentAnnouncements', announcements),
    setLoading: (state, loading) => Vue.set(state, 'loading', loading),
    setEditing: (state, editing) => Vue.set(state, 'editing', editing),
    setDeleting: (state, deleting) => Vue.set(state, 'deleting', deleting),
    setAdding: (state, adding) => Vue.set(state, 'adding', adding),
    setErrorLoadingIndex: (state, errorLoadingIndex) => Vue.set(state, 'errorLoadingIndex', errorLoadingIndex),
    setErrorEditingItem: (state, errorEditingItem) => Vue.set(state, 'errorEditingItem', errorEditingItem),
    setErrorDeletingItem: (state, errorDeletingItem) => Vue.set(state, 'errorDeletingItem', errorDeletingItem),
    setErrorAddingItem: (state, errorAddingItem) => Vue.set(state, 'errorAddingItem', errorAddingItem),
    setSuccessEditingItem: (state, successEditingItem) => Vue.set(state, 'successEditingItem', successEditingItem),
    setSuccessDeletingItem: (state, successDeletingItem) => Vue.set(state, 'successDeletingItem', successDeletingItem),
    setSuccessAddingItem: (state, successAddingItem) => Vue.set(state, 'successAddingItem', successAddingItem),
    reset(state) {
      const newState = initialState()
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key]
      })
    },
  },
  // -----------------------------------------------------------------
  actions: {
    loadIndex: (context) => {
      context.commit('setLoading', true)
      return AxiosRestService
        .get('/announcements')
        .then((response) => {
          context.commit('setIndex', response.data)
          context.commit('setErrorLoadingIndex', false)
        })
        .catch(() => context.commit('setErrorLoadingIndex', true))
        .finally(() => context.commit('setLoading', false))
    },
    loadUnauthorized: (context) => AxiosRestService.get('/announcements/currentlyActive').then((response) => {
      context.commit('setCurrentAnnouncements', response.data)
    }),
    addItem: (context, item) => {
      context.commit('setAdding', true)
      return AxiosRestService
        .post('/announcements/add', item)
        .then(() => {
          context.dispatch('loadIndex')
          context.dispatch('setSuccessStatus', 'setSuccessAddingItem')
          context.commit('setErrorAddingItem', false)
        })
        .catch(() => {
          context.commit('setErrorAddingItem', true)
          context.commit('setSuccessAddingItem', false)
        })
        .finally(() => context.commit('setAdding', false))
    },
    editItem: (context, item) => {
      context.commit('setEditing', true)
      return AxiosRestService
        .patch(`/announcements/edit/${item.id}`, item)
        .then(() => {
          context.dispatch('loadIndex')
          context.dispatch(
            'setSuccessStatus',
            'setSuccessEditingItem',
          )
          context.commit('setErrorEditingItem', false)
        })
        .catch(() => {
          context.commit('setErrorEditingItem', true)
          context.commit('setSuccessEditingItem', false)
        })
        .finally(() => context.commit('setEditing', false))
    },
    deleteItem: (context, announcementId) => {
      context.commit('setDeleting', true)
      return AxiosRestService
        .delete(`/announcements/delete/${announcementId}`)
        .then(() => {
          context.dispatch('loadIndex')
          context.dispatch(
            'setSuccessStatus',
            'setSuccessDeletingItem',
          )
          context.commit('setErrorDeletingItem', false)
        })
        .catch(() => {
          context.commit('setErrorDeletingItem', true)
          context.commit('setSuccessDeletingItem', false)
        })
        .finally(() => context.commit('setDeleting', false))
    },
    setSuccessStatus: (context, statusSetter) => {
      context.commit(statusSetter, true)
      setTimeout(() => context.commit(statusSetter, false), 3000)
    },

    setCurrentAnnouncements: (context, announcements) => {
      context.commit('setCurrentAnnouncements', announcements)
    },

    removeFromCurrentAnnouncements: (context, announcementId) => {
      const { currentAnnouncements } = context.getters

      const newAnnouncements = currentAnnouncements.filter(
        (announcement) => announcement.id !== announcementId,
      )

      context.commit('setCurrentAnnouncements', newAnnouncements)
    },
  },
}
