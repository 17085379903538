<template>
  <v-card
    class="audi-notification px-3 py-3"
    :class="isLight ? 'light' : 'dark'"
    elevation="12"
  >
    <v-progress-linear
      v-if="!announcement && notification.progress"
      :color="isLight ? '#D8D8D8' : '#000'"
      :background-color="isLight ? 'white' : '#313131'"
      :value="notification.progress"
      class="mt-n3 mb-3 ml-n3"
      style="width: 18.5rem; max-width: 90vw"
    />
    <v-row style="margin-right: -14px">
      <v-col cols="10">
        {{ `${notification.message}` }}
      </v-col>
      <v-col cols="2" class="pa-0">
        <div @click="removeNotification" style="cursor: pointer;">
          <audi-icon
            :dark="!isLight"
            icon="cancel"
            size="48px"
            large
          />
        </div>
      </v-col>
    </v-row>
    <!-- Only show the card actions if content for the actions slot was given -->
    <v-card-actions v-if="!!$slots.actions" class="ml-n2 mt-3">
      <slot name="actions"/>
    </v-card-actions>
  </v-card>
</template>

<script>
import AudiIcon from '@/components/audi/AudiIcon.vue';

export default {
  name: 'audi-notification',
  components: { AudiIcon },
  props: {
    dark: Boolean,
    light: Boolean,
    announcement: {
      type: Boolean,
      default: false,
    },
    notification: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isLight() {
      if (this.dark && this.light) {
        return true;
      }

      if (this.dark) {
        return false;
      }

      if (this.light) {
        return true;
      }

      return this.notification.id % 2 === 0;
    },
  },
  methods: {
    removeNotification() {
      this.$emit('close');
      if (this.announcement) {
        this.$store.dispatch('announcements/removeFromCurrentAnnouncements', this.notification.id);
        return;
      }

      this.$store.commit('notifications/removeNotification', this.notification.id);
    },
  },
}
</script>

<style scoped>
.audi-notification {
  width: 18.5rem;
  max-width: 90vw;
  height: auto;
  font-size: 15px;
  line-height: 24px;
}
.audi-notification.dark {
  color: #F2F2F2;
  background-color: #313131;
}
.audi-notification.light {
  color: #333;
  background-color: white;
}
.audi-notification.mobile {
  font-size: 14px;
  line-height: 20px;
}
</style>
