import Vue from 'vue'
import AxiosRestService from '@/network/axiosRestService';

const initialState = () => ({
  index: [],
  loading: false,
  errorLoadingIndex: false,
})

export default {
  namespaced: true,
  // -----------------------------------------------------------------
  state: initialState(),
  // -----------------------------------------------------------------
  getters: {
    index: (state) => state.index,
    loading: (state) => state.loading,
    loadingAddEdit: (state) => state.loadingAddEdit,
    adding: (state) => state.adding,
    errorAddingItem: (state) => state.errorAddingItem,
    successAddingItem: (state) => state.successAddingItem,
    editing: (state) => state.editing,
    errorEditingItem: (state) => state.errorEditingItem,
    successEditingItem: (state) => state.successEditingItem,
    errorLoadingIndex: (state) => state.errorLoadingIndex,
  },
  // -----------------------------------------------------------------
  mutations: {
    setIndex: (state, sites) => Vue.set(state, 'index', sites),
    setLoading: (state, val) => Vue.set(state, 'loading', val),
    setLoadingAddEdit: (state, val) => Vue.set(state, 'loadingAddEdit', val),
    setAdding: (state, adding) => Vue.set(state, 'adding', adding),
    setErrorAddingItem: (state, errorAddingItem) => Vue.set(state, 'errorAddingItem', errorAddingItem),
    setSuccessAddingItem: (state, successAddingItem) => Vue.set(state, 'successAddingItem', successAddingItem),
    setEditing: (state, editing) => Vue.set(state, 'editing', editing),
    setErrorEditingItem: (state, errorEditingItem) => Vue.set(state, 'errorEditingItem', errorEditingItem),
    setSuccessEditingItem: (state, successEditingItem) => Vue.set(state, 'successEditingItem', successEditingItem),
    setErrorLoadingIndex: (state, val) => Vue.set(state, 'errorLoadingIndex', val),
    reset(state) {
      const newState = initialState()
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key]
      })
    },
  },
  // -----------------------------------------------------------------
  actions: {
    loadIndex: (context) => {
      context.commit('setLoading', true)
      return AxiosRestService
        .get('/sites')
        .then((response) => {
          context.commit('setIndex', response.data)
          context.commit('setErrorLoadingIndex', false)
        })
        .catch(() => context.commit('setErrorLoadingIndex', true))
        .finally(() => context.commit('setLoading', false))
    },
    addItem: (context, site) => {
      context.commit('setAdding', true)
      context.commit('setLoadingAddEdit', true)
      return AxiosRestService
        .post('/sites/add', site)
        .then(() => {
          context.dispatch('loadIndex')
          context.commit('setSuccessAddingItem', true)
          context.commit('setErrorAddingItem', false)
        })
        .catch(() => {
          context.commit('setErrorAddingItem', true)
          context.commit('setSuccessAddingItem', false)
        })
        .finally(() => {
          context.commit('setAdding', false)
          context.commit('setLoadingAddEdit', false)
        })
    },
    editItem: (context, item) => {
      context.commit('setEditing', true)
      context.commit('setLoadingAddEdit', true)
      return AxiosRestService
        .patch(`/sites/edit/${item.id}`, item)
        .then(() => {
          context.dispatch('loadIndex')
          context.commit('setSuccessEditingItem', true)
          context.commit('setErrorEditingItem', false)
        })
        .catch(() => {
          context.commit('setErrorEditingItem', true)
          context.commit('setSuccessEditingItem', false)
        })
        .finally(() => {
          context.commit('setEditing', false)
          context.commit('setLoadingAddEdit', false)
        })
    },
    setSuccessStatus: (context, statusSetter) => {
      context.commit(statusSetter, true)
      setTimeout(() => context.commit(statusSetter, false), 3000)
    },
  },
}
