<template>
  <v-row
    justify="center"
    v-if="showToSDialog"
  >
    <v-dialog
      v-model="isOpen"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ $t('global-documents-update-title') }}
        </v-card-title>
        <v-card-text>
          {{ $t('global-documents-update-text') }}
        </v-card-text>
        <v-card-text>
          <v-row>
            <v-checkbox
              class="ml-3"
              v-model="agreementCheckbox"
              color="#007BFF"
              :rules="[rules.required]"
              :ripple="false"
            >
              <template v-slot:label>
                <span style="color:#222">
                  {{ $t('I accept: ') }}
                  <a
                    rel="noopener noreferrer"
                    class="pa-0"
                    @click.stop
                    target="_blank"
                    :href="tosURL"
                  >{{ $t('ToS') }}</a>
                  {{ $t('and') }}
                  <a
                    rel="noopener noreferrer"
                    class="pa-0"
                    @click.stop
                    target="_blank"
                    :href="privacyURL"
                  >{{ $t('Privacy Policy') }}</a>
                </span>
              </template>
            </v-checkbox>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="black"
            :dark="true"
            depressed
            :ripple="false"
            @click="acceptButton"
            :disabled="!agreementCheckbox"
          >
            {{ $t('Accept') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import urls from '@/router/urls';

export default {
  data() {
    return {
      isOpen: true,
      agreementCheckbox: false,
    };
  },
  mounted() {
    this.$store.dispatch('documents/fetchGlobalAgreement');
  },
  computed: {
    tosURL() {
      return urls.TERMS_OF_SERVICE
    },
    privacyURL() {
      return urls.PRIVACY_POLICY
    },
    showToSDialog() {
      return !this.$store.getters['documents/globalAgreement'] && !this.$store.getters.user.isAdmin();
    },
    rules() {
      return {
        required: (v) => !!v || this.$t('Required'),
      };
    },
  },
  methods: {
    acceptButton() {
      this.isOpen = false;
      this.$store.commit('documents/updateGlobalAgreement', true);
      this.$store.dispatch('documents/acceptGlobalDocuments');
    },
  },
}
</script>
