/* eslint-disable class-methods-use-this */
import { GetterTree, MutationTree, ActionTree } from 'vuex';
import Notification, { NotificationTypes } from '@/model/notification';
import BaseModule from '../module';
import { State, RootState } from '../types';

interface NotificationsState extends State{
  notifications: Notification[];
}

class NotificationsModule extends BaseModule<NotificationsState> {
  protected initialState(): NotificationsState {
    return {
      notifications: [],
    }
  }

  protected buildGettersTree(): GetterTree<NotificationsState, RootState> {
    return {
      notifications: (state) => state.notifications,
    }
  }

  protected buildMutationsTree(): MutationTree<NotificationsState> {
    return {
      addNotification(state, notification) {
        state.notifications.push(notification)
      },
      removeNotification(state, id) {
        const index = state.notifications.findIndex(
          (notification) => notification.id === id,
        )
        state.notifications.splice(index, 1)
      },
      setProgressOfNotification(state, { id, progress }) {
        const index = state.notifications.findIndex(
          (notification) => notification.id === id,
        )

        if (index === -1) {
          return;
        }

        state.notifications[index].progress = progress
        state.notifications = [...state.notifications] // trigger update
      },
      setNotifications(state, notifications) {
        state.notifications = notifications
      },
    }
  }

  protected buildActionsTree(): ActionTree<NotificationsState, RootState> {
    return {
      info(context, notification: Notification) {
        notification.setType(NotificationTypes.INFO);
        context.dispatch('scheduleNotification', notification)
      },
      success(context, notification) {
        notification.setType(NotificationTypes.SUCCESS);
        context.dispatch('scheduleNotification', notification)
      },
      error(context, notification) {
        notification.setType(NotificationTypes.ERROR);
        context.dispatch('scheduleNotification', notification)
      },
      warning(context, notification) {
        notification.setType(NotificationTypes.WARNING);
        context.dispatch('scheduleNotification', notification)
      },
      scheduleNotification(context, notification) {
        context.commit('addNotification', notification)

        if (notification.timeout < 0) {
          return
        }

        if (notification.displayTimeout) {
          let counter = 0
          const intervalId = setInterval(() => {
            counter += 200
            const progress = ((notification.timeout - counter)
                        / notification.timeout)
                    * 100
            context.commit('setProgressOfNotification', {
              id: notification.id,
              progress,
            })
            if (counter >= notification.timeout) {
              clearInterval(intervalId)
              context.commit('removeNotification', notification.id)
            }
          }, 200)
        } else {
          setTimeout(
            () => context.commit('removeNotification', notification.id),
            notification.timeout,
          )
        }
      },
      removeOldNotifications(context) {
        const currentNotifications = context.getters.notifications

        // eslint-disable-next-line max-len
        const cleanedNotifications = currentNotifications.filter((notification: Notification) => {
          const timeoutInSeconds = notification.timeout / 1000
          return notification.creationDate + timeoutInSeconds > Date.now()
        })
        context.commit('setNotifications', cleanedNotifications)
      },
    }
  }
}

export default new NotificationsModule(true);
