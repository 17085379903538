<template>
  <div class="pos-fixed d-flex flex-column">
    <!-- Use vue's transition group here, since a
    vuetify transition only works on single elements -->
    <transition-group name="notifications">
      <audi-notification
        v-for="alert in alerts"
        :key="alert.notification.id"
        :notification="alert.notification"
        :announcement="alert.isAnnouncement"
        class="mb-1"
      />
    </transition-group>
  </div>
</template>
<script>
import AudiNotification from '@/components/audi/AudiNotification.vue';

export default {
  components: { AudiNotification },
  computed: {
    alerts() {
      return [
        ...this.$store.getters['announcements/currentAnnouncements'].map((value) => (
          { isAnnouncement: true, notification: value }
        )),
        ...this.$store.getters['notifications/notifications'].map((value) => (
          { isAnnouncement: false, notification: value }
        )),
      ];
    },
  },
};
</script>
<style scoped>
.pos-fixed {
  position: fixed;
  width: 18.5rem;
  max-width: 90vw;
  top: 0.75rem;
  right: 0.75rem;
  z-index: 9999999 !important;
}

/* Transitions for transition-group 'notifications' */
.notifications-move {
  transition: all 0.5s cubic-bezier(0.75, 0.02, 0.5, 1);
  transition-delay: 0.4s;
}
.notifications-enter-active,
.notifications-leave-active {
  transition: all 0.5s ease;
}
.notifications-enter-from,
.notifications-leave-to {
  opacity: 0;
}
/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.notifications-leave-active {
  position: absolute;
}
</style>
